import React from 'react';
import Img from 'gatsby-image';
import styles from './perks-entertainment-section.module.scss';

export default ({ fluid }) => {
  return (
    <div className="columns">
      <div
        className={`columns column is-12 is-10-desktop is-offset-1-desktop has-text-centered-mobile`}
      >
        <div className="column is-6">
          <div className="content">
            <p className={`content ${styles.subheader}`}>
              Entertainment discounts
            </p>
            <p className="content">
              No matter where you are in the country, you can use your Trustaff
              Tickets employee code to receive up to <b>60% off</b> theme parks,
              movie tickets, concerts, plays, shopping, sporting events, and
              much more.
            </p>
            <p>
              With partners like <b>Walt Disney World</b>, Disneyland, Universal
              Studios, Busch Gardens, Cirque Du Soleil, Six Flags, Cedar Fair,
              and many others, we are sure you can find something fun near your
              assignment with a discount on us!
            </p>
            <p className={`content ${styles.subheader}`}>Planet Fitness</p>
            <p className="content">
              Get a discount at over{' '}
              <b>
                1,600{' '}
                <a
                  href="https://www.planetfitness.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Planet Fitness
                </a>{' '}
                locations
              </b>{' '}
              all over the country. The first month and enrollment fee will be
              waived for White Card or Black Card memberships. There’s also no
              commitment, so you can change your membership as you change
              assignments!
            </p>
            <p className={`content ${styles.subheader}`}>Hello Fresh</p>
            <p className="content">
              Trustaff travelers enjoy exclusive discounts when they sign up for{' '}
              <a
                href="https://www.hellofresh.com/how-it-works/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Hello Fresh
              </a>{' '}
              meal delivery services. It's a stress-free way to ensure you're
              eating right with fresh ingredients before (or after) your shift.
            </p>
          </div>
        </div>
        <div
          className={`column is-5 is-offset-1 ${styles.noSidePaddingMobile}`}
        >
          <Img
            fluid={fluid}
            className={styles.aboutImageWrapper}
            alt="travel nursing benefits"
          />
        </div>
      </div>
    </div>
  );
};
