import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/layout';
import styles from './perks-discounts.module.scss';
import HeroImage from '../../components/hero-image/hero-image';
import HeroImageMini from '../../components/hero-image/hero-image-mini';
import PerksEntertainmentSection from '../../components/trustaff-traveler/perks/perks-entertainment-section';
import PerksApparelSection from '../../components/trustaff-traveler/perks/perks-apparel-section';
import PerksHousingSection from '../../components/trustaff-traveler/perks/perks-housing-section';
import { Helmet } from 'react-helmet';

export default ({ data }) => {
  const getTrustaffTravelerPerksImageEdge = name => {
    return data.allFile.edges.find(e => e.node.name === name);
  };
  const ogImageUrlPath =
    getTrustaffTravelerPerksImageEdge('traveler-perks-og').node.childImageSharp
      .fluid.src;
  const fullOgImageUrl = `https://www.trustaff.com${ogImageUrlPath}`;
  return (
    <>
      <Helmet>
        <title>Traveler perks &amp; discounts - Trustaff</title>
        <meta charSet="utf-8" />
        <meta
          name="Description"
          content="In addition to great pay and service, all our travelers are eligible for exclusive discounts and perks just for being a part of the Trustaff family."
        />
        <meta property="og:title" content="Traveler perks &amp; discounts" />
        <meta
          property="og:description"
          content="In addition to great pay and service, all our travelers are eligible for exclusive discounts and perks just for being a part of the Trustaff family."
        />
        <meta property="og:image" content={fullOgImageUrl} />
        <meta
          property="og:url"
          content="https://www.trustaff.com/trustaff-traveler/perks-discounts/"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content="Trustaff" />
        <meta property="og:type" content="website" />
        <meta name="twitter:site" content="@Trustaff" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Layout>
        <HeroImage
          fluid={
            getTrustaffTravelerPerksImageEdge('traveler-perks').node
              .childImageSharp.fluid
          }
        >
          <div className={'columns'}>
            <div className="column is-7 is-offset-1">
              <div className={styles.heroTextArea}>
                <h1 className={`title is-3 has-text-white`}>
                  Traveler perks &amp; discounts
                </h1>
                <p className={`content`}>
                  In addition to great pay and service, all our travelers are
                  eligible for exclusive discounts and perks just for being a
                  part of the Trustaff family! Browse the list below for
                  information about current partnerships.
                </p>
                <p className={`content`}>
                  These travel nursing discounts are only available to
                  healthcare professionals currently on assignment with
                  Trustaff. <b>Ready to claim one of your perks?</b> Contact
                  your recruiter for a complete list and claim instructions.
                </p>
              </div>
            </div>
          </div>
        </HeroImage>
        <div className={`section ${styles.sectionNoSidePaddingMobile}`}>
          <PerksEntertainmentSection
            fluid={
              getTrustaffTravelerPerksImageEdge('traveler-perks-sidebar-1').node
                .childImageSharp.fluid
            }
          />
        </div>
        <div
          className={`section ${styles.sectionNoSidePaddingMobile} ${styles.sectionBackgroundGrey}`}
        >
          <PerksApparelSection
            fluid={
              getTrustaffTravelerPerksImageEdge('traveler-perks-sidebar-2').node
                .childImageSharp.fluid
            }
          />
        </div>
        <div className={`section ${styles.sectionNoSidePaddingMobile}`}>
          <PerksHousingSection
            fluidTop={
              getTrustaffTravelerPerksImageEdge('traveler-perks-sidebar-3').node
                .childImageSharp.fluid
            }
            fluidBottom={
              getTrustaffTravelerPerksImageEdge('traveler-perks-sidebar-4').node
                .childImageSharp.fluid
            }
          />
        </div>
        <HeroImageMini
          fluid={
            getTrustaffTravelerPerksImageEdge('traveler-perks-big-city').node
              .childImageSharp.fluid
          }
        />
      </Layout>
    </>
  );
};

export const pageQuery = graphql`
  {
    allFile(filter: { relativePath: { regex: "/^trustaff-traveler-perks/" } }) {
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 2400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
