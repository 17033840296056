import React from 'react';
import Img from 'gatsby-image';
import styles from './perks-housing-section.module.scss';

export default ({ fluidTop, fluidBottom }) => {
  return (
    <div className="columns">
      <div
        className={`columns column is-12 is-10-desktop is-offset-1-desktop has-text-centered-mobile`}
      >
        <div className="column is-6">
          <h3 className="title is-5 has-text-info">
            Travel and housing discounts
          </h3>
          <div className="content">
            <p className={`content ${styles.subheader}`}>
              <a
                href="https://www.wyndhamhotels.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Wyndham
              </a>
            </p>
            <p className={`content`}>
              With hotels and resorts on 5 continents in 22 countries, Trustaff
              offers travelers discounted Wyndham rates up to <b>25% off</b> the
              regular price.
            </p>
            <p className={`content ${styles.subheader}`}>
              <a
                href="https://www.wyndhamhotels.com/laquinta"
                target="_blank"
                rel="noopener noreferrer"
              >
                La Quinta
              </a>
            </p>
            <p className={`content`}>
              Wake up on the bright side for as long as you need. Our travelers
              receive up to <b>25% off</b> standard rates.
            </p>
            <p className={`content ${styles.subheader}`}>
              <a
                href="https://www.redroof.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Red Roof Inn
              </a>
            </p>
            <p className={`content`}>
              Red Roof has over 500 hotels that are pet friendly at no extra
              cost. Our travelers and their fluffy companions can stay for up to{' '}
              <b>25% off</b>.
            </p>
            <p className={`content ${styles.subheader}`}>
              <a
                href="https://www.extendedstayamerica.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Extended Stay America
              </a>
            </p>
            <p className={`content`}>
              Stay as long as you need! Trustaff can offer you up to{' '}
              <b>25% off</b> their already low rates.
            </p>
            <p className={`content ${styles.subheader}`}>
              <a
                href="https://www.bestwestern.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Best Western
              </a>
            </p>
            <p className={`content`}>
              Our travelers enjoy up to <b>15% off</b> at over 2,000 Best
              Western hotels in the country.
            </p>
            <p className={`content ${styles.subheader}`}>
              <a
                href="https://www.avis.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                AVIS car rental
              </a>
            </p>
            <p className={`content`}>
              This award-winning rental company with 5,500 locations in over 165
              countries will help our travelers whenever they need a ride.
              Trustaff will also help you save up to <b>25% off!</b>
            </p>
            <p className={`content ${styles.subheader}`}>
              <a
                href="https://www.hotelengine.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Hotel Engine
              </a>
            </p>
            <p className={`content`}>
              This free-to-join service offers access to over 80,000 hotels in
              almost any country in the world. With up to 60% off hotels
              already, our travelers can save up to an <b>additional 25% off</b>
              .
            </p>
          </div>
        </div>
        <div className={`is-hidden-mobile column is-5 is-offset-1`}>
          <Img
            fluid={fluidTop}
            className={styles.aboutImageWrapper}
            alt="travel nursing housing"
          />
          <Img
            fluid={fluidBottom}
            className={`is-hidden-mobile ${styles.img2} ${styles.aboutImageWrapper}`}
            alt="travel nursing housing"
          />
        </div>
      </div>
    </div>
  );
};
