import React from 'react';
import Img from 'gatsby-image';
import styles from './perks-apparel-section.module.scss';

export default ({ fluid }) => {
  return (
    <div className="columns">
      <div
        className={`columns column is-12 is-10-desktop is-offset-1-desktop has-text-centered-mobile`}
      >
        <div className="column is-6">
          <h3 className="title is-5 has-text-info">Apparel discounts</h3>
          <div className="content">
            <p className={`content ${styles.subheader}`}>Dansko shoes</p>
            <p className={`content`}>
              Through{' '}
              <a
                href="http://www.jackiescrubs.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Jackie's Uniforms
              </a>
              , Trustaff travelers enjoy <b>20% off the entire site</b> with no
              exclusions, including premium brands like Dansko shoes.
            </p>
            <p className={`content ${styles.subheader}`}>
              <a
                href="https://www.cherokee4less.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Cherokee 4 Less
              </a>
            </p>
            <p className={`content`}>
              Enjoy <b>20% off</b> any order and free standard shipping on
              orders over $50! Brands include Dickies, Dr. Scholl’s, Fila USA,
              K-Swiss, Reebok, Careisma, HeartSoul, Cherokee, Workwear, and
              more.
            </p>
            <p className={`content ${styles.subheader}`}>
              <a
                href="https://medcouturescrubshop.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Med Couture Scrubs
              </a>
            </p>
            <p className={`content`}>
              Choose from hundreds of scrubs options with new collections and
              prints. Our travelers receive <b>20% off</b> on any order and free
              standard shipping on orders over $50 for all of your stylish scrub
              needs.
            </p>
            <p className={`content ${styles.subheader}`}>WonderWink Scrubs</p>
            <p className={`content`}>
              <a
                href="https://www.wonderwinkscrubshop.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                WonderWink
              </a>{' '}
              has a variety of scrub options for any budget. Take advantage of a{' '}
              <b>20% discount</b> on any order and free standard shipping on
              orders over $50.
            </p>
          </div>
        </div>
        <div
          className={`column is-5 is-offset-1 ${styles.noSidePaddingMobile}`}
        >
          <Img
            fluid={fluid}
            className={styles.aboutImageWrapper}
            alt="travel nursing health insurance"
          />
        </div>
      </div>
    </div>
  );
};
